




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CREATE_CONTACT } from '@/modules/contact/store';
import { Type, WriteContact } from '@/modules/contact/types';
import { ApiResponse } from '@/components/types';
import DepartmentTypeSelect from '@/modules/contact/components/DepartmentTypeSelect.vue';
import { createContact } from '@/modules/contact/model';

const Contact = namespace('contact');

@Component({
  components: {
    DepartmentTypeSelect
  }
})
export default class ContactBaseForm extends Vue {
  @Prop({ type: String, required: true }) type!: Type;
  @Contact.Action(CREATE_CONTACT) addContact!: (contact: WriteContact) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  contact = createContact([]);

  init(observer?: any) {
    this.contact = createContact([]);
    this.error = null;
    observer && observer.reset();
  }

  cancel(observer?: any) {
    this.init(observer);
    this.$emit('cancel');
  }

  back(observer: any) {
    this.init(observer);
    this.$emit('back');
  }

  save() {
    this.error = null;

    return this.addContact({ ...this.contact });
  }
}
