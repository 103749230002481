




























import { Component, Vue } from 'vue-property-decorator';
import { contactTypes } from '../../helper';

@Component
export default class ContactSelectForm extends Vue {
  get options(): Array<{ component: string, type: string, label: string }> {
    return [
      { component: 'court-form', type: 'mental_health_court', label: 'Betreuungsgericht' },
      { component: 'health-insurance-form', type: 'health_insurance', label: 'Krankenkasse' },
      { component: 'bank-form', type: 'bank', label: 'Bank' },
      { component: 'department-form', type: 'amt', label: 'Amtskontakt' },
      ...contactTypes().filter((type) => type.value !== 'other')
        .map((contact) => ({ component: 'contact-base-form', type: contact.value, label: contact.text }))
    ].sort((first, second) => first.label.localeCompare(second.label));
  }
}
