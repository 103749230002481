


















import { Component, Vue } from 'vue-property-decorator';
import { Type } from '../types';
import ContactSelectForm from './CreateContactDialog/ContactSelectForm.vue';
import ContactBaseForm from './CreateContactDialog/ContactBaseForm.vue';
import DepartmentForm from './CreateContactDialog/DepartmentForm.vue';
import BankForm from './CreateContactDialog/BankForm.vue';
import CourtForm from './CreateContactDialog/CourtForm.vue';
import HealthInsuranceForm from './CreateContactDialog/HealthInsuranceForm.vue';

@Component({
  components: {
    ContactSelectForm,
    ContactBaseForm,
    DepartmentForm,
    BankForm,
    CourtForm,
    HealthInsuranceForm
  }
})
export default class CreateDialog extends Vue {
  dialog: boolean = false;
  contact: { component: string, type: Type } = { component: 'contact-select-form', type: 'other' };

  cancel() {
    setTimeout(() => {
      this.contact = { component: 'contact-select-form', type: 'other' };
    }, 200);

    this.dialog = false;
  }
}
