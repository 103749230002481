

























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ContactTypeSelect from '@/modules/contact/components/ContactTypeSelect.vue';
import { ApiResponse } from '@/components/types';
import { createContact } from '@/modules/contact/model';
import { WriteContact } from '@/modules/contact/types';
import { CREATE_CONTACT } from '@/modules/contact/store';

const Contact = namespace('contact');

@Component({
  components: {
    ContactTypeSelect
  }
})
export default class HealthInsuranceForm extends Vue {
  @Contact.Action(CREATE_CONTACT) addContact!: (contact: WriteContact) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  healthInsurance: WriteContact = createContact(['health_insurance']);

  init(observer?: any) {
    this.healthInsurance = createContact(['health_insurance']);
    observer && observer.reset();
    this.error = null;
  }

  cancel(observer?: any) {
    this.init(observer);
    this.$emit('cancel');
  }

  back(observer?: any) {
    this.init(observer);
    this.$emit('back');
  }

  async save() {
    this.error = null;

    const { error } = await this.addContact(this.healthInsurance);

    return { error };
  }
}
