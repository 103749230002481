

























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CREATE } from '../../../court/store';
import { WriteCourt } from '../../../court/types';
import { ApiResponse } from '@/components/types';
import { WriteContact } from '@/modules/contact/types';
import { createCourt } from '@/modules/contact/model';
import { CREATE_CONTACT } from '@/modules/contact/store';

const Court = namespace('court');
const Contact = namespace('contact');

@Component({})
export default class CourtForm extends Vue {
  @Court.Action(CREATE) addCourt!: (court: WriteCourt) => Promise<ApiResponse>;
  @Contact.Action(CREATE_CONTACT) addContact!: (contact: WriteContact) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  court: WriteCourt & WriteContact = createCourt();

  init(observer?: any) {
    this.court = createCourt();
    observer && observer.reset();
    this.error = null;
  }

  cancel(observer?: any) {
    this.init(observer);
    this.$emit('cancel');
  }

  back(observer?: any) {
    this.init(observer);
    this.$emit('back');
  }

  async save() {
    this.error = null;

    const { error: secError } = await this.addContact(this.court);
    const { error } = await this.addCourt(this.court);

    return { error: error || secError };
  }
}
