












import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { FETCH_CSV } from "@/modules/contact/store";

const Contact = namespace('contact');

@Component({})
export default class DownloadAction extends Vue {
  @Prop({ type: String, default: 'btn-info' }) color!: string;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: true }) icon!: boolean;
  @Prop({ type: String }) label?: string;

  @Contact.Action(FETCH_CSV) fetchCSV!: () => Promise<ApiResponse>;

  loading: boolean = false;
  error: any = null;

  get filename(): string {
    return `kontakte.csv`;
  }

  async download() {
    this.error = null;
    this.loading = true;

    const { error, content } = await this.fetchCSV();

    if (error) {
      this.error = error;
      this.loading = false;
      return;
    }

    this.loading = false;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', this.filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
