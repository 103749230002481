































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CREATE } from '../../../bank/store';
import { WriteBank } from '../../../bank/types';
import { ApiResponse } from '@/components/types';
import { createBank } from '@/modules/contact/model';
import { CREATE_CONTACT } from '@/modules/contact/store';
import { WriteContact } from '@/modules/contact/types';

const Bank = namespace('bank');
const Contact = namespace('contact');

@Component({})
export default class BankForm extends Vue {
  @Bank.Action(CREATE) addBank!: (bank: WriteBank) => Promise<ApiResponse>;
  @Contact.Action(CREATE_CONTACT) addContact!: (contact: WriteContact) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  bank: WriteBank & WriteContact = createBank();

  init(observer?: any) {
    this.bank = createBank();
    observer && observer.reset();
    this.error = null;
  }

  cancel(observer?: any) {
    this.init(observer);
    this.$emit('cancel');
  }

  back(observer?: any) {
    this.init(observer);
    this.$emit('back');
  }

  async save() {
    this.error = null;

    const { error: bankError } = await this.addContact(this.bank);
    const { error } = await this.addBank(this.bank);

    return { error: error || bankError };
  }
}
