































































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_CONTACT_LIST, SET_CONTACT_PAGE } from '../store';
import { PaginatableMixin } from '@/views/mixin';
import { ApiResponse, Pagination } from '@/components/types';
import { AddressList, Contact } from '../types';
import ContactPersonsDialog from '../components/ContactPersonsDialog.vue';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import CardRow from '@/components/CardRow.vue';
import PhoneRow from '@/components/PhoneRow.vue';
import UpdateContactDialog from '@/modules/contact/components/UpdateContactDialog.vue';
import CreateContactDialog from '@/modules/contact/components/CreateContactDialog.vue';
import DownloadAction from "@/modules/contact/components/DownloadAction.vue";
import AppIterator from '@/components/AppIterator.vue';
import CardHeader from '@/components/CardHeader.vue';

const Address = namespace('contact');

const CreateLetterButton = () => import('@/modules/correspondence/components/CreateLetterButton.vue');

@Component({
  components: {
    DownloadAction,
    CardHeader,
    AppIterator,
    CreateContactDialog,
    CreateLetterButton,
    ContactPersonsDialog,
    ListSearchDialog,
    UpdateContactDialog,
    CardRow,
    PhoneRow
  }
})
export default class List extends PaginatableMixin {
  @Address.State('addresses') list!: AddressList;
  @Address.Mutation(SET_CONTACT_PAGE) setPage!: (page: number) => void;
  @Address.Action(FETCH_CONTACT_LIST) fetch!: () => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  changeContact: Contact | null = null;

  pagination: Pagination = {
    sortBy: ['name'],
    itemsPerPage: 10,
    page: 1,
    pageCount: 0
  };

  get items() {
    return this.list.items
      .filter((contact) => !contact.types.includes('other'))
      .map((contact) => ({
        ...contact,
        address: `${contact.street}${contact.street ? ',' : ''} ${contact.zipCode} ${contact.city}`.trimLeft().trim(),
        personList: contact.contactPersons.map((person) => person.name).join(', '),
        typeList: contact.types.map((type) => this.$i18n.t(`contact.type.${type}`)).join(', ')
      }));
  }

  async created() {
    try {
      this.loading = true;

      await this.fetch();
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
