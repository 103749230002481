

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CREATE_CONTACT } from '../../store';
import { Type, WriteContact } from '../../types';
import { ApiResponse } from '@/components/types';
import { createContact } from '../../model';

const Contact = namespace('contact');

@Component({})
export default class ContactBaseForm extends Vue {
  @Prop({ type: String, required: true }) type!: Type;
  @Contact.Action(CREATE_CONTACT) addContact!: (contact: WriteContact) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  contact: WriteContact = createContact([this.type]);

  init(observer?: any) {
    this.contact = createContact([this.type]);
    observer && observer.reset();
    this.error = null;
  }

  cancel(observer?: any) {
    this.init(observer);
    this.$emit('cancel');
  }

  back(observer?: any) {
    this.init(observer);
    this.$emit('back');
  }

  save() {
    this.error = null;

    return this.addContact(this.contact);
  }
}
